import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { COMPANY_NAME } from "../constants/constants";
import { Link } from "gatsby";

export default function Navigation() {
  return (
    <Navbar className="bg-white border-bottom border-dark" expand="lg">
      <Navbar.Brand className="p-0 m-0 " href="/">
        {COMPANY_NAME}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="main-navigation" />

      <Navbar.Collapse id="main-navigation">
        <Nav className="bg-white mr-auto ml-auto">
          <Link to="/" className="nav-link">
            Home
          </Link>
          <Link to="/about" className="nav-link">
            About Us
          </Link>
          {/* Preschool */}
          <NavDropdown title="Preschool" id="basic-nav-dropdown">
            <Link to="/preschool/how-it-works" className="dropdown-item">
              How It Works
            </Link>
            <Link to="/preschool/acorns" className="dropdown-item">
              Acorns
            </Link>
            <Link to="/preschool/maples" className="dropdown-item">
              Maples
            </Link>
            <Link to="/preschool/oaks" className="dropdown-item">
              Oaks
            </Link>
          </NavDropdown>
          {/* Tutoring */}
          <NavDropdown title="Tutoring" id="basic-nav-dropdown">
            <Link to="/tutoring/how-it-works" className="dropdown-item">
              How It Works
            </Link>
            <Link to="/tutoring/preschool-to-k9" className="dropdown-item">
              Preschool To K9
            </Link>
            <Link to="/tutoring/highschool" className="dropdown-item">
              Highschool
            </Link>
          </NavDropdown>
          <Link to="/resources" className="nav-link">
            Resources
          </Link>
          <Link to="/covid" className="nav-link">
            Covid-19
          </Link>
          {/* Registration */}
          <Link to="/registration" className="nav-link">
            Registration
          </Link>

          <Link to="/contact" className="nav-link">
            Contact Us
          </Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
