import React from "react";
import Header from "../header";
import Navigation from "../navigation";
import Footer from "../footer";
import { Helmet } from "react-helmet";

export default function BaseLayout({ children, pageTitle, pageDescription }) {
  return (
    <>
      <Helmet>
        <html lang="en" amp />
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <body className="root" />
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="http://gmacademy.ca/" />
      </Helmet>
      <Header>
        <Navigation />
      </Header>
      <main>{children}</main>
      <Footer />
    </>
  );
}
